import React from 'react'
import './footer.css'

import { useStateContext } from '../../contexts/ContextProvider';

// import signup from '../../data/signup.png'
import signup from '../../data/btn-playtest.png'
import newsletter from '../../data/newsletter.png'

const Footer = () => {
  const { setShowPopup, setPopupType } = useStateContext();

  return (
    <div className='main__footer'>
      <div className='main__footer-interaction'>
        <button type='button' onClick={() => { setShowPopup(true); setPopupType('player') }}>
          <img src={signup} alt='sign up' />
        </button>

        <button className='main__footer-interaction-mobile-hide' type='button' onClick={() => { setShowPopup(true); setPopupType('newsletter') }}>
          <img src={newsletter} alt='newsletter' />
        </button>

      </div>
      <p>Copyright CARV Labs, 2024.</p>
    </div>
  )
}

export default Footer