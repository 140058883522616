import React, { useState } from 'react';
import { Link } from 'react-router-dom';  //NavLink
import { RiCloseLine } from 'react-icons/ri';
import { useStateContext } from '../../contexts/ContextProvider';

import './navbar.css';
import logo from '../../data/logo_nav.png';
// import signup from '../../data/signup_nav.png';
import signup from '../../data/btn-nav-playtest-black.png';
import menuicon from '../../data/burger-icon.png'

// import hoverboardimg from '../../data/hoverboardimg.webp'




// BEM -> Block Element Modifier

const Menu = ({ levysOriginsActive, setLevysOriginsActive }) => (
  <>
    {/* <p><a href="#home">Home</a></p>
  <p><a href="#docs">Docs</a></p>
  <p><a href="#contact">Contact</a></p> */}
    <Link to="/" onClick={() => { 
      const element = document.getElementById('firstsec');
      element?.scrollIntoView({
        behavior: 'smooth'
      })
    }}><p>OVERVIEW</p></Link>
    {/* <Link to="/lore" onClick={() => { setLevysOriginsActive(!levysOriginsActive) }}><p>STORY</p></Link> */}
    <Link to="/" onClick={() => { 
      const element = document.getElementById('secondsec');
      element?.scrollIntoView({
        behavior: 'smooth'
      })
    }}><p>UPGRADES</p></Link>
    <Link to="/" onClick={() => { 
      const element = document.getElementById('thirdsec');
      element?.scrollIntoView({
        behavior: 'smooth'
      })
    }}><p>UPCOMING</p></Link> 
  </>
)

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  const { userLoggedIn, setUserLoggedIn, levysOriginsActive, setLevysOriginsActive, setShowPopup, setPopupType } = useStateContext();

  //const activeLink = 'text-blue';
  //const normalLink

  return (
    <div className='main__navbar'>
      <div className='main__navbar-links_logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='main__navbar-links_container'>
          <Menu setLevysOriginsActive={setLevysOriginsActive} levysOriginsActive={levysOriginsActive }/>
        </div>
      {/* <div className='main__navbar-links'>
        
      </div> */}
      <div className='main__navbar-sign'>
      {/* <Link to="/levityspace" onClick={() => { }}><p>Sign in</p></Link> */}
        {/* <button type='button' onClick={() => {setUserLoggedIn(!userLoggedIn)}}>
          <img src={signup} alt="Sign Up"/>
        </button> */}

        <button type='button' onClick={() => {setShowPopup(true); setPopupType('player')}}>
          <img src={signup} alt="Sign Up"/>
        </button>

        {/* <div className='main__hero-image'>
              <img src={hoverboardimg} alt='Levity Racing' />
            </div> */}
      </div>
      <div className='main__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
          : <img src={menuicon} alt="Menu" onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='main__navbar-menu_container scale-up-center'>
            <div className='main__navbar-menu_container-links'>
              <Menu setLevysOriginsActive={setLevysOriginsActive} levysOriginsActive={levysOriginsActive }/>
              <div className='main__navbar-menu_container-links-sign'>
                {/* <Link to="/" onClick={() => {setUserLoggedIn(!userLoggedIn)}}><p>SIGN UP</p></Link> */}
                <Link to="/" onClick={() => {setShowPopup(true); setPopupType('player')}}><p>PLAY TEST</p></Link>

                {/* <button type='button'>Sign Up</button> */}
                {/* <button type='button' onClick={() => {setUserLoggedIn(!userLoggedIn)}}>
                  <img src={signup} alt="Sign Up"/>
                </button> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar