// import React from 'react'
// import React, { useState } from 'react';
import './popup.css';

import { useStateContext } from '../../contexts/ContextProvider';


const Popup = () => {
    const { setShowPopup, popupType, setPopupType } = useStateContext();
    return (
        <div className="main__popup">
            
            
            {popupType === 'player' && 
            
            // {/* <button className="close-btn" onClick={() => { setShowPopup(false); setPopupType('') }}>X</button> */}
            
            <div className="main__popup-inner-playersignup">
                
                <iframe title="Player Sign Up Form" src="https://docs.google.com/forms/d/e/1FAIpQLSd3q2geFauV0DcJySHwmktn7ooJUqLdkD0D83tZfb84_4NzXg/viewform?embedded=true" min-height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                
            </div>}

            {popupType === 'newsletter' && 
            
            <div className="main__popup-inner-newslettersignup">    

                <iframe title="Newsletter Sign Up Form" src="https://embeds.beehiiv.com/1c612642-fa38-4573-b7c2-98e8fb731535?slim=true" data-test-id="beehiiv-embed" max-height="120" frameborder="0" scrolling="no" margin="0" border-radius="0px  !important" background-color="transparent">Loading...</iframe>
            
            </div>}

            <button type="button" onClick={() => { setShowPopup(false); setPopupType('') }}>Close</button>

        </div>
    )
}

export default Popup