import React from 'react'
import './hero.css'
// import { Link } from 'react-router-dom';  //NavLink

import { useStateContext } from '../../contexts/ContextProvider';

// import hoverboardimg from '../../data/hoverboardimg.webp'
// import signup from '../../data/signup.png'
import signup from '../../data/btn-playtest.png'
import newsletter from '../../data/newsletter.png'
// import signupmobile from '../../data/signup_mobile.png'
import signupmobile from '../../data/btn-mobile-playtest.png'
import newslettermobile from '../../data/newsletter_mobile.png'
import steambtn from '../../data/steam_btn.webp'
import metabtn from '../../data/meta_btn.webp'
import xlogo from '../../data/xlogo.png'
import dlogo from '../../data/dlogo.png'
import ilogo from '../../data/ilogo.png'




const Hero = () => {
  const { userLoggedIn, setShowPopup, setPopupType } = useStateContext();
  // const {  } = useStateContext();
  return (
    <>
      {/* //  <div className='gradient_bg_black_fade'>  */}
      <div id="overlay"></div>
      <div className='main__hero section__padding main__hero__bg' id='home'>
        {/* <div className=''> */}



        {/* <div className='section__padding'> */}
        <div className='main__hero-content'>

          {/* <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
           */}

          <h1>Get Ready<br/> To Ride</h1>
          <p>Experience a thrilling new way to race against others in VR in the hottest upcoming multiplayer combat racing arcade adventure, LEVITY.</p>
          
          {/* <p>Sign up</p> */}

          <div className='main__hero-content__interaction'>

            {/* <button type='button'>Sign Up for Newsletter</button>
            <button type='button'>Early Game Tester</button> */}
            {/* <Link to="/playersignup" onClick={() => {}}><button type='button'>PLAYER TESTER SIGN-UP</button> </Link>  */}
            <button type='button' onClick={() => { setShowPopup(true); setPopupType('player') }}>
              <img className='desktop-only' src={signup} alt='sign up' />
              <img className='mobile-only' src={signupmobile} alt='sign up' />
            </button>

            {/* <button type='button' onClick={() => { setShowPopup(true); setPopupType('player') }}>
              <img src={steambtn} alt='Find Us in Steam Store' />
            </button>

            <button type='button' onClick={() => { setShowPopup(true); setPopupType('player') }}>
            <img src={metabtn} alt='Find Us in Meta Quest Store' />
            </button> */}

            {/* <div className='main__hero-content__interaction-input'> */}
            {/* <input type='email' placeholder='Your Email Address' /> */}
            <button type='button' onClick={() => { setShowPopup(true); setPopupType('newsletter') }}>
              <img className='desktop-only' src={newsletter} alt='newsletter' />
              <img className='mobile-only' src={newslettermobile} alt='newsletter' />
            </button>

            <div className='main__hero-content__interaction-socials'>
              <a className='main__hero-content__interaction-socials-x' href='https://www.x.com/LevityRace' target="_blank" rel="noreferrer">
                <img src={xlogo} alt='Follow Us on X/Twitter' />
              </a>
              <a className='main__hero-content__interaction-socials-d' href='https://www.x.com/LevityRace' target="_blank" rel="noreferrer">
                <img src={dlogo} alt='Join Our Discord Channel' />
              </a>
              <a className='main__hero-content__interaction-socials-ig' href='https://www.x.com/LevityRace' target="_blank" rel="noreferrer">
                <img src={ilogo} alt='Follow Us on Instagram' />
              </a>
            </div>
            {/* </div> */}
          </div>

          {/* <div className='main__hero-image'>
                <img src={hoverboardimg} alt='Levity Racing' />
              </div> */}

          {userLoggedIn && (<p>USER LOGGED IN</p>)}


        </div>
        {/* </div> */}


        {/* </div> */}
      </div>
      {/* // </div>  */}
    </>
  )
}

export default Hero