import React from 'react'
import './studiocontent.css'

const StudioContent = () => {
  return (
    <div className='main__studiocontent'>
      
      <div className='main__studiocontent__img-bg'>
        <div className='main__studiocontent-content'>
          {/* <p>- STAY TUNED -</p> */}
          {/* <p>- COMING SOON -</p> */}
        </div>
      </div>
    </div>
  )
}

export default StudioContent