import React from 'react'
import { StudioContent } from '../containers';


const Studio = () => {
  return (
    <StudioContent />
  )
}

export default Studio