import React from 'react'
import './loresection.css'

// import { useStateContext } from '../../contexts/ContextProvider';

const LoreSection = () => {

  // const { levysOriginsActive, setLevysOriginsActive } = useStateContext();

  return (
    <div className='main__loresection'>

      <div className='main__loresection__img-bg__levy'>
        {/* <div className='main__loresection__back-button'>
          {levysOriginsActive && (<button onClick={() => setLevysOriginsActive(false)}><p>Back</p></button>)}
        </div> */}
        
        <div className='main__loresection__overlay'>
        <div className='main__loresection-content'>
          <p>- STAY TUNED -</p>
          {/* <p>- COMING SOON -</p> */}
        </div>
        </div>
      </div>
    </div>
  )
}

export default LoreSection