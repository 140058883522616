import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

// const initialState = {
//     userProfile: false,
// }

export const ContextProvider = ({ children }) => {
    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [levysOriginsActive, setLevysOriginsActive] = useState(false);
    const [popupType, setPopupType] = useState('');

    return (
        <StateContext.Provider value={{
            test: 'test',
            userLoggedIn,
            setUserLoggedIn,
            showPopup,
            setShowPopup,
            levysOriginsActive,
            setLevysOriginsActive,
            popupType,
            setPopupType,
        }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);