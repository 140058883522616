import React from 'react'
import './fourthmidsection.css'

const FourthMidSection = () => {
  return (
    <div className='main__fourthmidsection'>
    <div className='main__fourthmidsection__textbox'>
    {/* <div className='main__firstsection__textbox-accent'>
      <img src={accent} alt='accent' />
    </div> */}
    <h1>Be one of the first to experience a new way to play in VR</h1>
    <p>Levity will be in closed alpha testing soon, so sign up to get your place and be one the first racers in a new dawn!</p>
  </div>
</div>
  )
}

export default FourthMidSection