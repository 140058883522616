import React from 'react'
import './secondmidsection.css' 

const SecondMidSection = () => {
  return (
    <div className='main__secondmidsection'>
        <div className='main__secondmidsection__textbox'>
        {/* <div className='main__firstsection__textbox-accent'>
          <img src={accent} alt='accent' />
        </div> */}
         <h1>Race against your friends... or enemies</h1>
        <p>Blast off in an intense player versus player death race full of blistering speeds and gigantic explosions , make sure to bring an extra pair of underwear.</p>
      </div>
    </div>
  )
}

export default SecondMidSection